.homeContainerOne{
    /* background-color: #C3BAB0; */
    background-color: #fff;
    font-family: Playfair;
}

.nav-item {
    position: relative; 
    transition: transform 0.3s ease; 
    display: inline-block; 
    padding-bottom: 5px; 
    overflow: hidden; 
}

.nav-item::after {
    content: ''; 
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5px; 
    background-color: #C3BAB0; 
    transform: scaleX(0); 
    transition: transform 0.5s ease; 
    transform-origin: left; 
}

.nav-item:hover::after {
    transform: scaleX(1); 
}

.homeContainerTwo {
    display: flex;          
    flex-direction: column; 
    align-items: center;    
    justify-content: center; 
    width: 100%;
    /* background-color: #C3BAB0;  */
    background-color: #fff; 
    padding: 6%;
}

.secondContainerContent{
    display: flex;
    flex-direction: column;
    width: 150%;
    gap: 10px
}

.ContainerTwoContent {
    font-size: 150%;
    font-weight:450;
    color: black; 
    font-family:Playfair;
    display: flex;
}

.ContainerTwoContent ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

.ContainerTwoContent li {
    position: relative;
    padding: 0 100px;
    color: #fff;
    font-size: 250%;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: slideInFromLeft 1s ease-out forwards;
    opacity: 0; 
}

.ContainerTwoContent li img {
    width: 120px; 
    height: auto;
    margin-bottom: 10px; 
}

.ContainerTwoContent li:nth-child(1) {
    animation-delay: .8s; 
}

.ContainerTwoContent li:nth-child(2) {
    animation-delay: 1.75s; 
}

.ContainerTwoContent li:nth-child(3) {
    animation-delay: 2.5s; 
}

.ContainerTwoContent li:not(:last-child)::after {
    content: ''; 
    position: absolute;
    right: 0; 
    top:15%; 
    bottom: 0; 
    width: 2px; 
    height: 20vh;
    background-color: #fff; 
    justify-content: center;
    align-items: center;
}

.homeCoverImage {
    position: relative;
    width: 200%; 
    box-shadow: -5px 17px 5px 0px rgba(0,0,0,0.75);
}

.homeCoverImage img {
    width: 150%; 
}

.ContainerTwoContent p{
    padding: 0% 10% 0% 10%;
}

.secondContainer{
    width: 100vw;
    height: 75vh;
    /* background-color: #C3BAB0; */
    background-color: #fff;
    text-align: center;
    font-family: Playfair;
    padding: 5%;
    display: flex;
    flex-direction: row;
    color: #fff;
    gap: 40px;
}

.secondContainerContent h2{
    font-size: 200%;
    text-align: center;
    font-family: Playfair;
}

.secondContainerContent p{
    font-size: 120%;
    font-family: Playfair;
}

.homeBanner{
    padding-top: 5%;
}

.secondContainerContent div{
    background-color: #626D7B;
    border-color: #fff;
    border-width: 2px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: -5px 7px 5px 0px rgba(0,0,0,0.75);
}

@media (max-width: 768px) {
    .homeBanner {
        padding-top: 10%; 

    }
}

@media (max-width: 480px) {
    .homeBanner {
        padding-top: 15%; 

    }
}

.home-special{
    color: rgba(35,71,130);
    font-size: 100%;
    font-weight: 800;
}

.home-homespecial{
    color: #C3BAB0;
    font-size: 100%;
    font-weight: 800;
}

.home-special-two{
    color: #C3BAB0;
    font-size: 120%;
    font-weight: 800;
}

.absolute-bottom {
    position: absolute;
    bottom: -1240px;
    right: -80px;
}
