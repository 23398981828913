/* .navigation-banner {
    background: url('../utilities/ServicesBanner.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    min-height: 100px;
    margin: 0;
    padding: 0;
}

.ServicesContainerOne {
    background-color: #F1ECE7;
    padding-top: 8%;
}

.Services-Intro {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.text-containerOne {
    width: 50%;
    background-color: #626D7B;
    color: #ffffff;
}

.textP1,
.textP2 {
    color: #ffffff;
}

.textP1 {
    text-align: center;
    font-size: 300%;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.67);
    padding: 2%;
}

.textP2 {
    font-size: 150%;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.67);
    padding: 0px;
    padding: 5%;
    margin-top: -50%;
}

.photo-containerOne {
    width: 50%;
    padding: 2%;
    background-color: #626D7B;
}

.photo-containerOne img {
    width: 100%;
    height: auto;
    border-radius: 5px;
} */

.head{
    background-color: #626D7B;
    color: #fff;
    font-size: 180%;
}

.text-component {
    display: flex;
    flex-direction: column; /* Changed from row to column */
    background-color: #626D7B;
    align-items: stretch; /* Stretch children to fill the width */
    position: relative;
}

.down-icon {
    position: absolute;
    right: 10px; /* Adjust right padding for icon placement */
    top: 50%;
    transform: translateY(-50%); /* Vertically center the icon */
    color: #fff;
    cursor: pointer; /* Indicates clickable area */
}

.text-component {
    display: block; /* changed from flex to block */
    background-color: #626D7B;
    color: #fff;
    margin-bottom: 10px; /* Space between accordion items */
}

.header {
    display: flex;
    justify-content: space-between; /* Ensures spacing between title and icon */
    align-items: center;
    padding: 10px;
    position: relative; /* Relative positioning inside flex parent */
}

.down-icon {
    transition: transform 0.8s ease;
}

.content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    max-height: 0; /* Start with closed state */
}

ul {
    margin: 0;
}

li{
    font-size: 125%;
    padding: 11px;
}

/* .sub-head{
    color: #C3BAB0;
    font-size: 120%;
} */

.subheading{
    color: #C3BAB0;
    font-size: 125%;
    font-weight:bolder;
}

.special-and {
    margin: 0 10px; /* Add 10px margin on both sides of the '&' */
    color: #C3BAB0; /* Example color */
    font-size: 2em; /* Smaller font size */
    font-style: italic; /* Italic style for emphasis */
}