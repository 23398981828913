.iframe-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; 
  }
  
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; 
  }
  
  .headings{
    font-size: 120%;
    font-weight: 500;
    padding: 10px;
  }

  .subheading{
    font-weight: 500;
  }