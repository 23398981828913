@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Playfair;
}

a{
  text-decoration: none !important;
}

a:hover{
  color: black;
}